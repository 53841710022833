.buyProductScreen {
  .whatYouGetRow {
    padding: 12px 35px;
    display: flex;
    align-items: center;
    border-radius: 16px;
    background-color: $blueLighter;
    margin-bottom: 24px;
    font-family: NunitoRegular;

    img {
      margin-right: 18px;
    }
  }

  .topSection {
    margin-bottom: 32px;
  }

  .withTopMargin {
    margin-top: 32px;
  }

  .styledOptionsSwitch {
    margin-bottom: 32px;
  }

  .productsWrapper {
    display: flex;
  }

  .productComponent {
    margin-right: 24px;
    width: 315px;
    max-width: 315px;
    min-height: 220px;
    background-color: $blueLighter;
    border-radius: 16px;
    border: 2px solid $primary;
    padding: 24px;
    font-family: NunitoSemiBold;
    box-sizing: border-box;

    &.disabled {
      border-color: $inputColor;
    }

    &:nth-last-child(1) {
      margin-right: 0;
    }

    .label {
      font-size: $fontSizeVeryBig;
      line-height: 22px;
      text-transform: uppercase;
      color: $primary;
    }

    .priceWrapper {
      margin: 24px 0;
      font-size: $fontSizeBig;
      line-height: 22px;
    }

    .benefitsWrapper {
      display: flex;
      flex-direction: column;
      margin-top: 2px;

      .benefit {
        display: list-item;
        margin-left: 28px;
      }
    }

    .styledButton {
      width: -webkit-fill-available;
      margin-top: 25px;
    }
  }

  .contactContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 64px;
    border: 2px solid $primary;
    padding: 40px;
    border-radius: 16px;

    .email {
      font-family: NunitoSemiBold;
      font-size: $fontSizeBig;
      line-height: 22px;
      color: $primary;
    }
  }
  .selectBubbleComponent {
    color: $mainBackground;
  }
}
