.settingsForm {
  padding: 0 24px 24px 24px;

  .styledTextInputWrapper {
    margin-top: 24px;

    .styledTextInput_label {
      color: $white;
    }

    .styledTextInput {
      &.withLabel {
        input {
          padding: 4px 12px;
        }
      }
    }

    input {
      font-family: NunitoRegular;
      padding: 5px 12px;
      font-size: $fontSizeSmall;
      line-height: 22px;
    }
  }

  .styledButton {
    margin-top: 24px;
    width: -webkit-fill-available;
  }
}
