.styledFiltersComponent {
  display: flex;
  border: 2px solid $primary;
  border-radius: 16px;
  background-color: $mainBackground;
  padding: 16px;

  .selectBubbleComponent {
    flex-wrap: nowrap;
  }

  span {
    color: $white;
    font-size: $fontSizeSmall;
    line-height: 22px;
    padding-bottom: 8px;
  }

  .section {
    display: flex;
    flex-direction: column;
    margin-right: 18px;

    &.big {
      flex: 1;
      margin-right: 0px;
    }

    &.disabled {
      opacity: 0.4;
      cursor: default;
    }
  }

  &.withoutGames {
    justify-content: center;
    .section {
      flex-direction: row;
      align-items: center;
    }

    span {
      padding-bottom: 0px;
      padding-right: 8px;
    }
  }
}
