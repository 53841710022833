.emptyInfoComponent {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $mainBackground;

  > img {
    width: 118px;
    height: 73px;
    margin-bottom: 24px;
    margin-top: 16px;
  }

  .text {
    font-size: $fontSizeMedium;
    line-height: 24px;
    color: $white;
    text-align: center;
    white-space: pre-line;
    margin-bottom: 16px;
  }

  .styledButton {
    margin-bottom: 16px;
  }
}
