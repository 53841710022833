.selectBubbleComponent {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .bubble {
    display: flex;
    font-family: NunitoBold;
    padding: 8px 12px;
    background-color: $backgroundOpacity_medium;
    border-radius: 16px;
    font-size: $fontSizeSmall;
    line-height: 28px;
    text-transform: uppercase;
    margin: 0 4px;
    white-space: nowrap;
    cursor: pointer;
    align-items: center;

    &.disabled {
      cursor: default;
      pointer-events: none;
    }

    &:nth-child(1) {
      margin: 0px 4px 0px 0px;
    }

    &:nth-last-child(1) {
      margin: 0px 0px 0px 4px;
    }

    &:not(.disabled) {
      &:hover {
        background-color: $white;
        color: $black;
      }
    }

    &.selected {
      background-color: $white;
      color: $black;
    }

    .selectedIcon {
      width: 21px;
      height: 21px;
      margin-right: 8px;
    }
  }
}
