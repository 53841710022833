.gameModeSpectificationRow {
  display: flex;
  margin-top: 16px;
  align-items: center;

  .iconBorder {
    border: 2px solid #000;
    min-width: 22px;
    min-height: 22px;
    height: 22px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      height: 10px;
    }
  }
  .text {
    padding-left: 8px;
    font-size: $fontSizeSmall;
    line-height: 22px;
  }
}
