.buyProductScreen {
  .orderComponent {
    display: flex;
    justify-content: space-between;

    .leftSide {
      width: 500px;
      display: flex;
      flex-direction: column;
      margin-right: 200px;

      .componentHeader {
        align-self: center;
      }

      .styledTextInputWrapper {
        margin-bottom: 24px;
      }

      .sectionHeader {
        margin-top: 32px;
      }

      .selectBubbleComponent {
        color: $mainBackground;
      }

      .styledTextInputWrapper {
        .styledTextInput_label {
          &.neutral {
            color: $white;
          }
        }
      }
    }

    .rightSide {
      display: flex;
      flex-direction: column;
      width: 400px;
      background-color: $blueLighter;
      border: 2px solid $primary;
      border-radius: 16px;
      padding: 40px;
      height: fit-content;
      align-items: center;

      .label {
        font-size: $fontSizeVeryBig;
        line-height: 22px;
        text-transform: uppercase;
        color: $primary;
      }

      .priceWrapper {
        display: flex;
        align-items: center;
        margin: 24px 0;
        font-size: $fontSizeBig;
        line-height: 22px;

        .price_small {
          font-size: $fontSizeMedium;
          line-height: 24px;
          padding-right: 8px;
        }

        .price_big {
          font-size: $fontSizeLargePlus;
          line-height: 46px;
        }
      }

      .benefitsWrapper {
        display: flex;
        flex-direction: column;
        margin-top: 24px;

        .benefit {
          display: list-item;
          margin-left: 28px;
        }
      }
    }
    .smallSpan {
      padding-top: 24px;
      font-size: $fontSizeSmall;
      line-height: 22px;
    }

    .orderForm {
      .sectionHeader {
        margin-bottom: 10px;
      }
    }
  }
}
