.styledTextButtonWithIcon {
  white-space: nowrap;

  .buttonIcon {
    margin-right: 8px;
    margin-bottom: 1px;
    width: 14px;
    height: 14px;
  }

  &.MuiButtonBase-root {
    text-transform: none;
    color: $mainBackground;
    box-sizing: border-box;
    transition: background-color 0.5s ease, color 0.5s ease,
      border-color 0.5s ease;
  }

  &.MuiButton-text {
    color: $primary;
    font-family: NunitoRegular;
    font-size: $fontSizeSmall;
  }

  &.error {
    &.MuiButtonBase-root {
      color: $error;
      border-color: $primary;
      font-family: NunitoBold;
    }
  }
}
