.scoresTab {
  // background-color: #3a4155;
  min-width: 720px;

  td {
    padding: 0;
    &.headerTc {
      background-color: rgba($mainBackground, 0.56);
      color: $white;
      font-size: $fontSizeSmall;
      line-height: 22px;
      padding: 12px 0;

      span {
        padding: 0px 8px;
      }

      &.small {
        span {
          border-left: 1px solid $gray; //TODO change to something lighter (more opacity)
        }
      }
    }

    .buttons {
      display: flex;
      align-items: center;
    }
  }

  tr {
    &.userRow {
      background-color: $mainBackground;
      td {
        padding: 7px 0px 7px 8px;
        width: 20%;
        color: $white;
      }
    }
  }

  th {
    color: $white;
    padding: 0;

    .buttons {
      display: flex;
      justify-content: flex-end;
    }

    button {
      color: $white;
    }
  }

  .scoresTab_filter_buttons {
    margin: 24px 0;
    display: flex;
    justify-content: center;

    :nth-child(1) {
      margin-right: 24px;
    }
  }

  .actionsTC {
    .buttons {
      justify-content: flex-end;

      &.withoutActionButtons {
        justify-content: flex-start;
      }
    }
  }
}
