.styledOptionsSwitch {
  display: flex;
  flex: 1;

  .styledOption {
    display: flex;
    flex: 1;
    min-width: 0;
    border: 1px solid $gray;
    font-size: $fontSizeSmall;
    line-height: 22px;
    text-transform: uppercase;
    font-family: NunitoBold;
    padding: 8px 46px;
    box-sizing: border-box;
    justify-content: center;
    cursor: pointer;
    color: #000;
    white-space: nowrap;

    &.disabled {
      cursor: default;
    }

    &.gray {
      background-color: $gray;
    }

    &.selected {
      background-color: $mainBackground;
      border-color: $primary;
      color: $primary;
    }

    //styles right only if there are two options
    &:nth-child(1) {
      border-radius: 8px 0px 0px 8px;
      &:not(.selected) {
        border-right: none;
      }
    }

    &:nth-child(2) {
      border-radius: 0px 8px 8px 0px;
      &:not(.selected) {
        border-left: none;
      }
    }
  }
}
