.MuiSnackbar-root {
  .MuiAlert-icon {
    display: none;
  }

  .MuiAlert-message {
    font-family: NunitoMedium;
    font-size: $fontSizeSmall;
  }
}
