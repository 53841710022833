.knowledgeBasesAccordion {
  .nestedAccordions {
    .accordion {
      margin-top: 18px;
    }
  }

  .MuiInputBase-root {
    margin-top: 8px;
  }

  .accordionSmallHeader {
    display: flex;
    align-items: center;
    margin-top: 16px;

    .downIcon {
      width: 16px;
      height: 16px;
      margin-right: 12px;
    }

    > span {
      font-size: $fontSizeSmall;
      line-height: 22px;
      color: $white;
      font-family: NunitoSemiBold;
    }
  }

  .bodyWrapper {
    .baseBodyWrapper {
      margin-top: 24px;
      background-color: #fff;
    }
  }
}
