body {
  background-color: $mainBackground;
}

.layout {
  height: 100vh;
  display: flex;
  flex-direction: column;

  .footer {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    padding: 0 120px;
    height: 70px;
    min-height: 70px;

    .rightSide {
      display: flex;
      align-items: center;
    }

    .leftSide {
      display: flex;
      align-items: center;
    }

    .section {
      margin-right: 40px;
      color: $white;
      font-size: $fontSizeSmall;
      text-decoration: none;

      &.link {
        cursor: pointer;
        color: $primary;
      }

      &:nth-last-of-type(1) {
        margin-right: 0;
      }

      &.clickable {
        cursor: pointer;
      }
    }
  }

  .preAuthForm {
    display: flex;
    flex-direction: column;
    min-width: 460px;
    width: 33vw;

    .styledCheckbox {
      margin-bottom: 22px;
    }
  }

  .progressWrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    .MuiCircularProgress-root {
      color: $primary;
    }
  }
}

.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  background-color: $mainBackground !important;
  color: $white !important;

  li {
    font-size: $fontSizeSmall;
    text-transform: uppercase;
    font-family: NunitoRegular;
  }

  .Mui-selected {
    background-color: rgba($primary, 0.08) !important;
  }
}

.MuiTooltip-popper {
  .MuiTooltip-tooltip {
    background-color: $primary;
    font-size: $fontSizeSmall;
    line-height: 22px;
    color: #000;
    font-family: NunitoRegular;
  }

  &.css-kudwh-MuiTooltip-arrow {
    color: $primary;
  }

  .MuiTooltip-arrow {
    color: $white;
  }
}

.modalBackground {
  position: fixed;
  background-color: $backgroundOpacity_dark;
  width: 100vw;
  height: 100vh;
  top: 0;
  z-index: 2;
}

.accordion {
  margin-top: 58px;
  .accordionHeader {
    font-size: $fontSizeVeryBig;
    line-height: 24px;
    text-transform: uppercase;
    color: $white;
    display: flex;
    align-items: center;

    .downIcon {
      width: 22px;
      height: 22px;
      margin-right: 8px;
    }
  }

  .downIcon {
    transition-duration: 0.2s;
  }

  &.open {
    .downIcon {
      transform: rotate(0deg);
    }
  }

  &:not(.open) {
    .downIcon {
      transform: rotate(-90deg);
    }
  }

  .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
    svg {
      color: $primary;
    }
  }
}
