.pageNotFoundScreen {
  height: 100vh;
  width: 100vw;
  color: $white;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  a {
    cursor: pointer;
    color: $primary;
  }

  .header {
    font-size: $fontSizeHuge;
    margin-bottom: 12px;
  }

  .info {
    font-size: $fontSizeMediumPlus;
  }
}
