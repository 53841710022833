.bagComponent {
  padding: 24px;
  color: $white;
  .header {
    font-size: $fontSizeSmall;
    line-height: 22px;
  }

  .info {
    font-size: $fontSizeBig;
    line-height: 28px;
    font-family: NunitoSemiBold;
    padding-bottom: 24px;
  }

  .styledButton.MuiButton-contained {
    margin-bottom: 24px;
    width: -webkit-fill-available;
  }

  .noActiveSubscription {
    margin-bottom: 24px;
  }

  .MuiButton-text {
    padding: 0;
    min-width: unset;
  }

  .label {
    text-decoration: none;
    text-transform: uppercase;
    color: $primary;
    font-size: $fontSizeSmall;
    font-family: NunitoSemiBold;
  }
}
