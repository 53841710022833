.styledCircularProgress {
  width: 80px;
  height: 80px;
  position: relative;

  .progressBar_gray {
    color: #f5f5f5;
    z-index: 0;
  }

  .progressBar {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    color: #a0d911;

    &.orange {
      color: $orange;
    }

    &.red {
      color: $error;
    }
  }
  .score {
    position: absolute;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    font-size: $fontSizeMediumPlus;
    line-height: 22px;
    font-family: NunitoRegular;
  }
}
