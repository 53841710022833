.myGameScreen {
  display: flex;
  flex: 1;
  flex-direction: column;

  > div {
    display: flex;
    flex-direction: column;
  }

  .clickableIcon {
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }

  .topSection {
    padding: 58px 0px;
    box-sizing: border-box;

    .header {
      padding-left: 120px;
      font-size: $fontSizeLarge;
      line-height: 120%;
      font-family: NunitoSemiBold;
      text-transform: uppercase;
      color: $white;
      margin-bottom: 6px;
    }
  }

  .bottomSection {
    flex: 1;
    padding: 6px 0px 58px 0px;
    box-sizing: border-box;
    background-color: $backgroundOpacity_light;
    backdrop-filter: blur(20px);
  }

  .noGames {
    color: $white;
    padding: 0 120px;
  }

  .section {
    margin-top: 58px;

    .sectionHeader {
      margin-left: 120px;
      font-family: NunitoSemiBold;
      font-size: $fontSizeVeryBig;
      line-height: 24px;
      text-transform: uppercase;
      color: $white;
      margin-bottom: 32px;
      width: fit-content;
    }

    .gameCard {
      width: 282px;
      min-width: 282px;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      border-radius: 16px;
      padding: 24px;
      box-sizing: border-box;

      &.empty {
        visibility: hidden;
      }

      &:nth-last-of-type(1) {
        margin-right: 0px;
      }

      .styledButton {
        margin-top: auto;
      }

      .category {
        font-family: NunitoSemiBold;
        font-size: $fontSizeVerySmall;
        line-height: 12px;
        display: flex;
        align-items: center;
        margin-bottom: 16px;

        img {
          margin-right: 8px;
        }

        .icons {
          display: flex;
          margin-left: auto;

          img {
            height: 24px;
            width: 24px;
          }
        }
      }

      .title {
        font-family: NunitoBold;
        font-size: $fontSizeMedium;
        line-height: 150%;
        text-transform: uppercase;
        margin-bottom: 8px;
      }

      .progress {
        font-size: $fontSizeMedium;
        line-height: 150%;
        text-transform: uppercase;

        .subtasksFinished {
          font-family: NunitoBold;
          padding-left: 6px;
        }
      }

      .score {
        margin-top: 8px;
        margin-bottom: 17px;
        display: flex;
        align-items: center;
        font-size: $fontSizeSmall;
        line-height: 22px;
        text-transform: uppercase;

        .noScore {
          font-size: $fontSizeSmall;
          line-height: 120%;
          text-transform: none;
        }

        .MuiLinearProgress-root {
          width: 85px;
          height: 8px;
          margin: 0 8px;
          border-radius: 12px;
          background-color: #f5f5f5;
          box-sizing: border-box;

          .MuiLinearProgress-bar {
            background-color: #a0d911;
          }
        }
      }
    }
  }
}

.contrast {
  .myGameScreen {
    .topSection {
      .gameCard {
        margin: 8px 0;
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.16);
      }
    }
  }
}
