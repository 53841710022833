.styledCheckbox {
  display: flex;
  flex-direction: column;

  .main {
    display: flex;
    justify-content: flex-start;
  }

  .error {
    color: $error;
    line-height: 1.66;
    font-family: NunitoBold;
    font-size: $fontSizeSmall;
    margin: 3px 14px 0px 14px;
  }

  .MuiCheckbox-root {
    color: $inputColor;
    padding: 0;
    padding-right: 9px;
    height: 24px;

    &:hover {
      color: $white;
      background-color: unset;
    }

    &.Mui-checked {
      color: $primary;
    }

    .MuiTouchRipple-root {
      display: none;
    }
  }
  .label {
    color: $inputColor;
    line-height: 14px;
    font-size: $fontSizeSmall;
    text-decoration: none;

    &.clickable {
      color: $primary;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
        text-underline-offset: 2px;
      }
    }
  }
}
