.styledIconButton {
  &.MuiButtonBase-root {
    text-transform: none;
    background-color: #fff;
    color: $black;
    font-family: NunitoBold;
    border: 2px $black solid;
    height: 42px;
    width: 42px;
    min-width: 42px;
    border-radius: 12px;
    text-transform: uppercase;
    line-height: 24px;
    box-sizing: border-box;
    transition: background-color 0.5s ease;

    &:hover {
      background-color: rgba(0, 0, 0, 0.16);
    }
  }
}
