.digitalProfileScreen {
  display: flex;
  flex: 1;
  flex-direction: column;

  .content {
    position: absolute;
  }

  .headerSection {
    display: flex;
    height: 150px;
    justify-content: space-between;
    align-items: center;
    padding: 0 120px;
    background-color: $mainBackground;

    > span {
      font-weight: 700;
      font-size: $fontSizeLarge;
      line-height: 120%;
      color: $white;
      text-transform: uppercase;
      font-family: NunitoBold;
    }
  }

  .mainSection {
    background-color: $digitalProfileBgc;
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 56px 120px 32px 120px;

    .info {
      font-weight: 400;
      font-size: $fontSizeBig;
      line-height: 150%;
      color: $white;
    }

    .categoriesWrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 48px;
      background-color: $mainBackground;
      border: 1px solid $primary;
      box-sizing: border-box;
      padding: 8px 16px 8px 24px;
      border-radius: 16px;
      align-items: center;

      .categories {
        display: grid;
        grid-template-columns: repeat(5, 1fr);

        font-size: $fontSizeVerySmall;
        line-height: 12px;
        text-transform: uppercase;
        color: $white;

        @media only screen and (max-width: 1700px) {
          grid-template-columns: repeat(2, 1fr);
        }
        @media only screen and (max-width: 1000px) {
          // grid-template-columns: repeat(2, 1fr);
        }

        .category {
          display: flex;
          align-items: center;
          margin: 8px;
          margin-right: 20px;
        }

        .icons {
          display: flex;
          flex: 1;
          justify-content: end;
        }

        .categoryName {
          padding-right: 10px;
          white-space: nowrap;
        }

        .categoryIcon {
          width: 24px;
          height: 24px;
          margin-right: 8px;
        }

        .finishedIcon {
          width: 21px;
          height: 21px;
          margin-right: 11px;
        }
      }
    }

    .lackOfFinishedGamesInfo {
      font-family: NunitoSemiBold;
      margin-top: 48px;
      font-size: $fontSizeBig;
      line-height: 24px;
      text-align: center;
      text-transform: uppercase;
      color: $white;

      .blue {
        color: $primary;
      }
    }

    .games {
      margin-top: 48px;
      display: grid;

      .gameCard {
        width: 220px;
        min-width: 220px;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        border-radius: 16px;
        padding: 24px;
        box-sizing: border-box;
        opacity: 0.56;
        border: 2px solid transparent;
        margin: 0 auto 24px auto;

        &.finished,
        &:hover {
          opacity: 1;
        }

        &.finished {
          background-color: #fff;
          border: 2px solid $primary;
        }

        .styledButton {
          margin-top: auto;
        }

        .category {
          font-family: NunitoSemiBold;
          font-size: $fontSizeVerySmall;
          line-height: 12px;
          display: flex;
          align-items: center;
          margin-bottom: 16px;

          img {
            margin-right: 8px;
          }

          .icons {
            display: flex;
            margin-left: auto;

            img {
              height: 24px;
              width: 24px;
            }
          }
        }

        .title {
          font-family: NunitoBold;
          font-size: $fontSizeMedium;
          line-height: 150%;
          text-transform: uppercase;
          margin-bottom: 8px;
        }
      }
    }
  }
}
