.slimTabsComponent {
  display: flex;
  margin: 24px 120px;
  border-bottom: 1px solid $backgroundOpacity_medium;
  box-sizing: border-box;
  justify-content: center;

  .tab {
    padding: 12px 16px;
    text-transform: uppercase;
    font-size: $fontSizeSmall;
    line-height: 22px;
    font-family: NunitoBold;
    color: $white;
    cursor: pointer;
    transform: translateY(1px);

    &.selected {
      color: $primary;
      border-bottom: 2px solid $primary;
    }
  }
}
