.styledTextInputWrapper {
  width: -webkit-fill-available;

  @-moz-document url-prefix() {
    width: -moz-available;
  }
  .styledTextInput_label {
    font-size: $fontSizeSmall;
    line-height: 22px;
    padding-bottom: 8px;
    color: $primary;

    &.neutral {
      color: #000;
    }
  }

  @-moz-document url-prefix() {
    .styledTextInput {
      width: -moz-available;
    }
  }

  .styledTextInput {
    width: -webkit-fill-available;

    .MuiInputBase-multiline {
      padding: 8px 12px;
      textarea {
        font-family: NunitoRegular;
      }
    }

    .Mui-error {
      font-family: NunitoBold;
      font-size: $fontSizeSmall;
    }

    &:hover {
      label {
        color: $white;
      }
    }

    &.withLabel {
      input {
        padding: 12px 20px;
      }
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: $inputColor;
    }

    .MuiOutlinedInput-root {
      background-color: #fff;
      border-radius: 8px;

      .MuiInputAdornment-root {
        button {
          color: $inputColor;
        }
      }
      &:hover {
        .MuiOutlinedInput-notchedOutline {
          border-color: $primary;
        }
      }

      &.Mui-focused {
        .MuiOutlinedInput-notchedOutline {
          border-color: $primary;
        }

        &:hover {
          border-color: $primary;
        }
      }

      &.Mui-error {
        .MuiOutlinedInput-notchedOutline {
          border-color: $error;
        }

        &:hover {
          border-color: $error;
        }
      }
    }

    input {
      font-family: NunitoRegular;
      color: #000;
      padding: 5px 12px;
      font-size: $fontSizeMedium;
      line-height: 22px;
    }

    input::placeholder {
      opacity: 1;
      color: $inputColor;
    }

    &.neutral {
      .MuiInputBase-root {
        border-color: $gray;
      }

      .MuiInputBase-root {
        &:hover {
          border-color: $primary;
        }
        &.Mui-focused {
          border-color: $primary;
        }
      }
    }
  }
}
