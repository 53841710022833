.styledLinearProgress {
  display: flex;
  align-items: center;

  .MuiLinearProgress-root {
    width: 85px;
    height: 8px;
    margin-right: 8px;
    border-radius: 12px;
    background-color: #f5f5f5;
    box-sizing: border-box;

    .MuiLinearProgress-bar {
      background-color: #a0d911;
    }
  }

  span {
    font-size: $fontSizeVerySmall;
    line-height: 20px;
  }
}
