.reportsAccordion {
  .nestedAccordions {
    .accordion {
      margin-top: 18px;
    }
  }

  .MuiInputBase-root {
    margin-top: 8px;
  }

  .bodyWrapper {
    .baseBodyWrapper {
      margin-top: 24px;
      background-color: #fff;
    }
  }
}
