.registerScreen {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $white;
  position: relative;

  .logoWrapper {
    display: flex;
    flex-direction: column;
    min-width: 460px;
    width: 33vw;
    align-items: center;
    justify-content: center;
    height: 250px;
    max-height: 250px;
  }

  .styledTextInput {
    margin-bottom: 22px;
  }

  .newAccountTextWrapper {
    white-space: pre-line;
    text-align: center;
    margin-bottom: 22px;
    margin-top: -15px;
  }

  .alreadyHaveAnAccountContainer {
    min-width: 460px;
    width: 33vw;
    display: flex;
    border-top: 1px solid #7a7a7a;
    margin-top: 42px;
    margin-bottom: 100px;
    padding-top: 22px;
    color: $white;
    justify-content: center;
  }
}
