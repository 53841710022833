.buyProductScreen {
  display: flex;
  flex-direction: column;
  padding: 56px 223px;
  align-items: center;
  color: $white;

  .componentHeader {
    font-family: NunitoSemiBold;
    font-size: $fontSizeLarge;
    line-height: 120%;
    text-transform: uppercase;
    margin-bottom: 56px;
    display: flex;
    align-items: center;
  }

  .sectionHeader {
    font-family: NunitoSemiBold;
    font-size: $fontSizeMedium;
    line-height: 120%;
    margin-bottom: 28px;
  }

  .capslockText {
    font-family: NunitoSemiBold;
    font-size: $fontSizeMedium;
    line-height: 22px;
    text-transform: uppercase;
    margin-bottom: 25px;
  }
}
