.gamesShow {
  display: flex;
  position: relative;
  overflow: hidden;
  // height: 404px;
  justify-content: space-between;
  z-index: 0;
  padding: 0 120px;

  .nextButton {
    z-index: 1;
    position: absolute;
    width: 48px;
    height: 48px;
    background: $primary;
    opacity: 0.64;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    top: 50%;
    bottom: 50%;
    transform: translate(0, -50%);

    &.left {
      left: 48px;
    }

    &.right {
      right: 48px;
    }

    &:hover {
      opacity: 0.84;
    }

    &.disabled {
      cursor: default;
      background: $gray;
      opacity: 0.44;
      pointer-events: none;

      &:hover {
        opacity: 0.44;
      }
    }
  }
}
