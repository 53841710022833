.styledButton {
  white-space: nowrap;

  .buttonIcon {
    margin-right: 8px;
    width: 24px;
    height: 24px;
  }

  &.MuiButtonBase-root {
    text-transform: none;
    color: $blueLighter;
    font-family: NunitoBold;
    height: 42px;
    box-sizing: border-box;
    text-transform: uppercase;
    padding: 16px 20px;
    line-height: 24px;
    transition: background-color 0.5s ease, color 0.5s ease,
      border-color 0.5s ease;
    border-radius: 12px;
  }

  &.MuiButton-contained {
    border: 2px $blueLighter solid;
    background-color: $primary;

    &:hover {
      background-color: $blueLighter;
      border-color: $primary;
      color: $primary;
    }

    &.Mui-disabled {
      background-color: $backgroundOpacity_medium;
      color: $blueLighter;
    }
  }

  &.MuiButton-text {
    color: $primary;
    font-family: NunitoSemiBold;

    &:hover {
      color: $white;
      background-color: unset;
    }
  }

  &.dark {
    &.MuiButtonBase-root {
      background-color: $blueLighter;
      color: $primary;
      border-color: $primary;

      &.Mui-disabled {
        border-color: $dark_gray;
        background-color: $inputColor;
        color: $blueLighter;
      }
    }

    &:hover {
      background-color: $primary;
      color: $blueLighter;
      border-color: $blueLighter;
    }
  }

  &.white {
    &.MuiButtonBase-root {
      background-color: $white;
      color: $dark_gray;
      border-color: $dark_gray;

      &.Mui-disabled {
        background-color: $backgroundOpacity_medium;
      }
    }

    &:hover {
      background-color: $dark_gray;
      color: $white;
      border-color: $dark_gray;
    }
  }
}
