.loginScreen {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $white;
  position: relative;
  background: $mainBackground;

  .logoWrapper {
    display: flex;
    flex-direction: column;
    min-width: 460px;
    width: 33vw;
    align-items: center;
    justify-content: center;
    height: 250px;
    min-height: 80px;
    max-height: 250px;
  }

  .styledTextInputWrapper {
    margin-bottom: 22px;
  }

  .welcomeTextWrapper {
    white-space: pre-line;
    text-align: center;
    margin-bottom: 22px;
    margin-top: -15px;
    display: flex;
    flex-direction: column;
    font-size: 18px;

    .small {
      font-size: 14px;
      white-space: pre-line;
      padding-top: 12px;
    }
  }

  .forgotYourPassword {
    margin-bottom: 22px;
  }

  .noAccountContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 460px;
    width: 33vw;
    border-top: 1px solid #7a7a7a;
    margin-top: 22px;
    padding-top: 22px;

    color: $white;
    justify-content: center;
  }
}
