body {
  --white: #fff;
  --black: #000;
  --primary: #00f8ff;
  --accent: #35e8ca;
  --secondaryColor: #6ec1e4;
  --mainBackground: #242b42;
  --blueLighter: #363d52;
  --error: #d32f2f;
  --orange: orange;
  --inputColor: #7a7a7a;
  --headerBackgroundColor: #dadada;
  --backgroundOpacity_light: rgba(255, 255, 255, 0.1);
  --backgroundWhiteOpacity: rgba(255, 255, 255, 0.16);
  --backgroundOpacity_medium: rgba(255, 255, 255, 0.56);
  --backgroundOpacity_dark: rgba(0, 0, 0, 0.45);
  --gray: #bfbfbf;
  --dark_gray: #434343;
  --primary_light: #e6fffb;
  --greenProgress: #a0d911;
  --digitalProfileBgc: #3a4155;

  &.contrast {
    --white: #000;
    --black: #fff;
    --primary: #00f8ff;
    --accent: #35e8ca;
    --secondaryColor: #196788;
    --mainBackground: #fff;
    --blueLighter: rgba(0, 0, 0, 0.56);
    --error: #d32f2f;
    --orange: orange;
    --inputColor: #7a7a7a;
    --headerBackgroundColor: #242424;
    --backgroundOpacity_light: rgba(0, 0, 0, 0.1);
    --backgroundWhiteOpacity: rgba(0, 0, 0, 0.16);
    --backgroundOpacity_medium: rgba(0, 0, 0, 0.16);
    --backgroundOpacity_dark: rgba(255, 255, 255, 0.45);
    --gray: #3c3c3c;
    --dark_gray: #b5b5b5;
    --primary_light: #001915;
    --greenProgress: #a0d911;
    --digitalProfileBgc: #e5e5e5;
  }
}

$white: var(--white);
$black: var(--black);
$primary: var(--primary);
$accent: var(--accent);
$secondaryColor: var(--secondaryColor);
$mainBackground: var(--mainBackground);
$blueLighter: var(--blueLighter);
$error: var(--error);
$orange: var(--orange);
$inputColor: var(--inputColor);
$headerBackgroundColor: var(--headerBackgroundColor);
$backgroundOpacity_light: var(--backgroundOpacity_light);
$backgroundWhiteOpacity: var(--backgroundWhiteOpacity);
$backgroundOpacity_medium: var(--backgroundOpacity_medium);
$backgroundOpacity_dark: var(--backgroundOpacity_dark);
$gray: var(--gray);
$dark_gray: var(--dark_gray);
$primary_light: var(--primary_light);
$greenProgress: var(--greenProgress);
$digitalProfileBgc: var(--digitalProfileBgc);

:export {
  white: $white;
  black: $black;
  primary: $primary;
  acceny: $accent;
  mainBackground: $mainBackground;
  error: $error;
  inputColor: $inputColor;
  backgroundOpacity_light: $backgroundOpacity_light;
  backgroundOpacity_medium: $backgroundOpacity_medium;
  backgroundOpacity_dark: $backgroundOpacity_dark;
  gray: $gray;
  dark_gray: $dark_gray;
  primary_light: $primary_light;
  greenProgress: $greenProgress;
  blueLighter: $blueLighter;
  orange: $orange;
  headerBackgroundColor: $headerBackgroundColor;
  digitalProfileBgc: $digitalProfileBgc;
}
