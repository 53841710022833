.mainScreen {
  display: flex;
  flex: 1;
  flex-direction: column;

  .centerSection {
    display: flex;
    flex: 1;
    flex-direction: column;
    // min-height: 540px;
    padding: 80px 120px 80px 120px;
    box-sizing: border-box;
    margin-right: auto;
    margin-left: auto;

    .digiLeader {
      font-size: $fontSizeLarge;
      color: $primary;
      width: min-content;
      padding-right: 20px;
      font-family: NunitoSemiBold;
      display: flex;
      flex-direction: column;
      padding-bottom: 20px;
    }

    span {
      white-space: pre-line;
    }

    .header {
      font-family: NunitoSemiBold;
      color: $white;
      font-size: $fontSizeHuge;
      line-height: 120%;
    }

    ul {
      margin: 0;

      li {
        padding-top: 24px;
        color: $white;
        font-size: $fontSizeBig;
        line-height: 28px;

        &:nth-of-type(2) {
          padding-top: 10px;
        }
      }
    }

    .styledButton {
      margin-top: 24px;
      width: fit-content;
    }
  }

  .gamesSection {
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: $backgroundOpacity_light;
    backdrop-filter: blur(20px);

    .gamesShow {
      padding: 0 120px 24px 120px;
    }

    .gameCard {
      width: 300px;
      min-width: 300px;
      height: 404px;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      border-radius: 16px;
      padding: 24px;
      box-sizing: border-box;
      // overflow: auto;

      -ms-overflow-style: none; /* Internet Explorer 10+ */
      scrollbar-width: none; /* Firefox */

      &.empty {
        visibility: hidden;
      }

      &:nth-last-of-type(1) {
        margin-right: 0px;
      }

      .styledButton {
        margin-top: auto;
      }

      .category {
        font-family: NunitoBold;
        font-size: $fontSizeVerySmall;
        line-height: 12px;
        display: flex;
        align-items: center;
        margin-bottom: 16px;

        img {
          margin-right: 8px;
        }
      }

      .title {
        font-family: NunitoBold;
        font-size: $fontSizeMedium;
        line-height: 125%;
        text-transform: uppercase;
      }

      .description {
        margin-top: 24px;
        font-size: $fontSizeSmall;
        line-height: 22px;
        margin-bottom: 24px;
        overflow: hidden;
        display: -webkit-box;
        text-overflow: ellipsis;
        -webkit-line-clamp: 6;
        -webkit-box-orient: vertical;
      }
    }

    .gameCard::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }
  }
}
