.pageTemplate {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 96px;
  position: relative;
  z-index: 0;

  > .MuiCircularProgress-root {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;

    circle {
      stroke: $primary;
    }
  }

  .menu {
    background-color: $backgroundOpacity_light;
    backdrop-filter: blur(20px);
    height: 96px;
    padding: 0 120px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;

    .tab {
      font-size: $fontSizeMedium;
      line-height: 22px;
      text-transform: uppercase;
      color: $white;
      margin-right: 40px;
      font-family: NunitoSemiBold;
      cursor: pointer;

      &:hover {
        color: $primary;
      }
    }

    .left {
      display: flex;
      align-items: center;

      .logo {
        width: 128px;
        height: 64px;
        object-fit: scale-down;
        margin-right: 40px;
        cursor: pointer;
      }
    }

    .right {
      display: flex;
      align-items: center;

      .userAccount {
        width: 32px;
        height: 32px;
        border-radius: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $gray;
        margin-left: 20px;
        cursor: pointer;

        .userIcon {
          width: 14px;
          height: 14px;
        }
      }

      .accessibleAccount {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 40px;
        cursor: pointer;
        width: 50px;
        height: 50px;
        color: #fff;
        position: relative;

        .accessibleIconBackground {
          background-color: $gray;
          border-radius: 16px;
          width: 32px;
          height: 32px;
          display: flex;
          justify-content: center;
          align-items: center;

          .MuiSvgIcon-root {
            width: 24px;
            height: 24px;
          }
        }

        .accessibilityPopup {
          display: flex;
          align-items: center;
          justify-content: space-between;
          position: absolute;
          width: 80px;
          height: 60px;
          background-color: $secondaryColor;
          padding: 0 8px;
          box-sizing: border-box;
          top: 44px;
          z-index: 5;
          border-radius: 8px;
        }
      }
    }

    .styledButton {
      &.MuiButtonBase-root {
        // width: 100px;
        font-size: $fontSizeMedium;
        text-transform: uppercase;
      }
    }
  }

  .pageTemplateDefaultModal {
    position: fixed;
    z-index: 3;
    background-color: #fff;
    padding: 16px 24px;
    box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
      0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    width: 400px;

    .topSection {
      display: flex;
    }

    .icon {
      width: 22px;
      height: 22px;
      margin-right: 18px;
    }

    .textWrapper {
      display: flex;
      flex-direction: column;

      .header {
        font-size: $fontSizeMedium;
        line-height: 24px;
        text-transform: uppercase;
      }

      .text {
        font-size: $fontSizeSmall;
        line-height: 22px;
      }
    }

    .buttons {
      margin-top: 24px;
      align-self: flex-end;
      display: flex;

      :nth-child(1) {
        margin-right: 8px;
      }
    }
  }

  // .MuiBackdrop-root {
  //   // position: absolute;
  //   top: 96px;
  //   justify-content: end;
  //   // bottom: 70px;
  // }

  .backgroudWithOpacity {
    position: fixed;
    background-color: $backgroundOpacity_dark;
    width: 100vw;
    height: calc(100vh - 96px);
    top: 96px;
    bottom: 0px;
    z-index: 2;
  }

  .userPanel {
    width: 400px;
    height: calc(100% - 96px);
    background-color: $mainBackground;
    position: fixed;
    right: 0;
    z-index: 3;
    overflow-y: auto;

    .headerWrapper {
      padding: 16px 24px;
      font-size: $fontSizeMedium;
      line-height: 24px;
      color: $white;
      border-bottom: 1px solid $blueLighter;
      display: flex;
      align-items: center;

      .closeIcon {
        width: 14px;
        height: 12px;
        margin-left: auto;
        cursor: pointer;
      }

      .back_arrow_icon {
        width: 14px;
        height: 14px;
        transform: rotate(90deg);
        margin-right: 14px;
        cursor: pointer;
      }
    }

    .section {
      padding: 9px 24px;
      font-size: $fontSizeSmall;
      line-height: 22px;
      color: $white;
      display: flex;
      align-items: center;
      cursor: pointer;

      .section_icon {
        width: 14px;
        height: 14px;
        margin-right: 12px;
      }

      .arrow_icon {
        width: 9px;
        height: 9px;
        transform: rotate(-90deg);
        margin-left: auto;
      }
    }
  }
}
