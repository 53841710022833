.educationalGoalsAccordion {
  color: $white;

  .subtask {
    .subtask_title {
      font-family: NunitoSemiBold;
      font-size: $fontSizeMedium;
      line-height: 24px;
      margin-bottom: 16px;
      margin-top: 40px;
    }

    &:nth-child(1) {
      .subtask_title {
        margin-top: 0px;
      }
    }

    .subtask_knowledge,
    .subtask_skills {
      margin-left: 40px;
      font-size: $fontSizeSmall;
      line-height: 22px;
      margin-bottom: 24px;

      .subtask_section_title {
        font-family: NunitoSemiBold;
      }

      ul {
        padding: 0 24px;
        margin: 0;

        li {
          font-family: NunitoRegular;
        }
      }
    }

    .subtask_task {
      background-color: $mainBackground;
      padding: 16px;
      display: flex;
      align-items: center;
      border-radius: 16px;
      font-size: $fontSizeSmall;
      line-height: 22px;
      margin-bottom: 40px;
      margin-right: 45px;
      margin-left: 40px;

      &:nth-last-child(1) {
        margin-bottom: 0;
      }

      .bold {
        font-family: NunitoSemiBold;
        padding-right: 4px;
      }

      img {
        width: 24px;
        height: 16px;
        margin-right: 8px;
      }
    }
  }
}
