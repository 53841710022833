@font-face {
  font-family: "NunitoMedium";
  src: local("NunitoMedium"),
    url("./assets/fonts/Nunito/Nunito-Medium.ttf") format(("truetype"));
  font-weight: 500;
}

@font-face {
  font-family: "NunitoRegular";
  src: local("NunitoRegular"),
    url("./assets/fonts/Nunito/Nunito-Regular.ttf") format(("truetype"));
}

@font-face {
  font-family: "NunitoSemiBold";
  src: local("NunitoSemiBold"),
    url("./assets/fonts/Nunito/Nunito-SemiBold.ttf") format(("truetype"));
  font-weight: 600;
}

@font-face {
  font-family: "NunitoBold";
  src: local("NunitoBold"),
    url("./assets/fonts/Nunito/Nunito-Bold.ttf") format(("truetype"));
  font-weight: 700;
}

body {
  margin: 0;
  font-family: NunitoRegular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
