.gameScreen {
  display: flex;
  flex: 1;
  flex-direction: column;

  .topSection {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 58px 120px;
    box-sizing: border-box;
    box-sizing: border-box;
    color: $white;

    @media only screen and (max-width: 768px) {
      flex-direction: column;
      width: 100%;
      padding: 58px 22px;
      justify-content: center;
      align-items: center;
    }

    .leftSide {
      width: calc(100vw - 400px - 240px - 45px);

      .categoryImage {
        display: flex;
        align-items: center;
        font-size: $fontSizeSmall;
        font-family: NunitoSemiBold;
        color: $white;
        margin-bottom: 8px;

        span {
          padding-left: 8px;
        }
      }

      .gameName {
        font-size: $fontSizeLarge;
        line-height: 120%;
        text-transform: uppercase;
        font-family: NunitoSemiBold;
        color: $white;
        margin-bottom: 24px;
      }

      .gameDescriptionHeader {
        font-family: NunitoSemiBold;
        text-transform: uppercase;
        color: $white;
        font-size: $fontSizeVeryBig;
        line-height: 24px;
        margin-bottom: 8px;
      }

      .gameDescription {
        font-size: $fontSizeSmall;
        line-height: 22px;
        color: $white;
        margin-bottom: 16px;
      }

      .tags {
        display: flex;

        .tag {
          display: flex;
          align-items: center;

          padding: 8px 12px;
          border-radius: 16px;
          background-color: $white;
          color: $mainBackground;
          margin-right: 14px;
          font-size: $fontSizeSmall;
          line-height: 28px;
          font-family: NunitoBold;
          text-transform: uppercase;

          img {
            width: 15px;
            height: 15px;
            margin-right: 8px;
          }
        }
      }
    }

    .rightSide {
      position: absolute;
      z-index: 1;
      display: flex;
      flex-direction: column;
      background: #fff;
      // height: 100%;
      right: 120px;
      border: 2px solid $primary;
      box-shadow: inset 0 -0em 0em rgba($primary, 0.1),
        0em 0em 2em rgba($primary, 0.3);
      border-radius: 16px;
      color: #000;

      @media only screen and (max-width: 768px) {
        margin-top: 24px;
      }

      .image {
        display: flex;
        padding: 60px 0;
        background-color: #000;
        border-radius: 16px 16px 0px 0px;
        align-items: center;
        justify-content: center;
        img {
          width: 80px;
          height: 54px;
        }
      }

      .infoSection {
        padding: 24px;
        width: 400px;
        box-sizing: border-box;
        .buttons {
          display: flex;
          margin-bottom: 16px;

          .styledButton {
            flex: 1;
            margin-right: 16px;
          }
        }

        .switchHeader {
          font-family: NunitoBold;
          text-transform: uppercase;
          font-size: $fontSizeMedium;
          line-height: 22px;
          margin-bottom: 16px;
        }

        .modesSpecificationHeader {
          font-size: $fontSizeSmall;
          line-height: 22px;
          font-family: NunitoBold;
          margin-top: 16px;
        }
      }
    }
  }

  .bottomSection {
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: $backgroundOpacity_light;
    backdrop-filter: blur(20px);
    padding-bottom: 56px;

    .bottomSectionComponentsWrapper {
      margin-left: 120px;
      width: calc(
        100vw - 400px - 240px - 45px
      ); // szerokość ekranu - szerokość kafelka z trybami gry - paddingi strony po 120px na stronę - odstęp między lewą częścią ekranu a kafelkiem
    }

    .bodyWrapper {
      padding-top: 32px;
    }
  }

  .noUserGame {
    display: flex;
    align-items: center;
    color: $white;
    font-size: $fontSizeMedium;
    line-height: 24px;

    .padlockIcon {
      height: 24px;
      width: 24px;
      margin-right: 16px;
    }
  }

  .styledSelectHeader {
    font-size: $fontSizeSmall;
    line-height: 22px;
    font-family: NunitoSemiBold;
    text-transform: uppercase;
    color: $white;
  }

  .accordionSmallHeader {
    display: flex;
    align-items: center;
    margin-top: 16px;

    .downIcon {
      width: 16px;
      height: 16px;
      margin-right: 12px;
    }

    > span {
      font-size: $fontSizeSmall;
      line-height: 22px;
      color: $white;
      font-family: NunitoSemiBold;
    }
  }

  .noDataToshow {
    color: $white;
    font-size: $fontSizeSmall;
    line-height: 22px;
    margin-top: 8px;
  }
}
