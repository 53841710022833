.MuiInputBase-root {
  box-sizing: border-box;
  border: 1px solid #00f8ff;
  border-radius: 16px !important;
  width: 100%;
  display: block;
  background-color: $mainBackground;

  .MuiSelect-select {
    box-sizing: border-box;
    padding: 7px 0 4px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    font-size: $fontSizeSmall;
    line-height: 22px;
    color: $white;
    font-family: NunitoRegular;

    text-align: center;
    text-transform: uppercase;
  }

  svg {
    color: $primary !important;
  }

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
}
