.scoresAccordion {
  .bodyWrapper {
    > .styledSelectHeader {
      margin-bottom: 32px;
    }
  }

  .mainGameScoreWrapper {
    margin-bottom: 32px;
    display: flex;
    align-items: center;
    border: 2px solid $primary;
    background-color: #fff;
    border-radius: 16px;
    font-size: $fontSizeBig;
    line-height: 150%;
    font-family: NunitoSemiBold;
    padding: 24px;
    justify-content: center;

    .styledCircularProgress {
      margin: 0 24px;
    }

    .uppercase {
      text-transform: uppercase;
    }
  }

  .subtask {
    background-color: $mainBackground;
    border: 2px solid $primary;
    border-radius: 16px;
    padding: 40px;
    margin-bottom: 40px;
    color: $white;

    .subtask_title {
      font-size: $fontSizeBig;
      line-height: 28px;
      font-family: NunitoSemiBold;

      .goal {
        margin-right: 4px;
        text-transform: uppercase;
      }
    }

    .mainScore {
      font-size: $fontSizeBig;
      line-height: 150%;
      text-transform: uppercase;
      display: flex;
      justify-content: center;
      padding: 24px;
      align-items: center;

      > span {
        font-family: NunitoSemiBold;
        padding-right: 24px;
      }
    }

    .subtask_section {
      .subtask_section_title {
        font-size: $fontSizeSmall;
        line-height: 22px;
        font-family: NunitoSemiBold;
        text-transform: uppercase;
      }

      li {
        font-size: $fontSizeSmall;
        line-height: 22px;
        text-transform: lowercase;
        margin-bottom: 24px;

        .subtask_li_div {
          display: flex;
          justify-content: space-between;
          // align-self: center;

          .MuiLinearProgress-root {
            width: 200px;
          }
        }
      }
    }

    .extraScores {
      background-color: $backgroundOpacity_light;
      border: 2px solid $gray;
      border-radius: 16px;
      padding: 16px;
      font-size: $fontSizeSmall;
      line-height: 22px;

      .title {
        font-family: NunitoSemiBold;
      }

      .scoresWrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 8px;

        .smallScore {
          display: flex;
          margin-right: 24px;
          .smallScore_title {
            margin-right: 8px;
          }

          .smallScore_score {
          }
        }
      }
    }
  }
}
