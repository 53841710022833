.customModal {
  position: fixed;
  z-index: 3;
  background-color: $white;
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
    0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  width: 500px;

  .headerWrapper {
    padding: 16px 24px;
    border-bottom: 1px solid $gray;
  }

  .mainContentWrapper {
    padding: 16px 24px;
    display: flex;
  }

  .buttons {
    display: flex;
    flex: 1;
    padding: 16px 24px;
    justify-content: flex-end;
    display: flex;
    border-top: 1px solid $gray;

    :nth-child(1) {
      margin-right: 8px;
    }
  }
}
