body {
  --fontSizeMedium: 16px;
  --fontSizeMediumPlus: 18px;
  --fontSizeSmall: 14px;
  --fontSizeVerySmall: 12px;
  --fontSizeBig: 20px;
  --fontSizeVeryBig: 24px;
  --fontSizeLarge: 32px;
  --fontSizeLargePlus: 38px;
  --fontSizeHuge: 50px;

  &.fontSizeLarge {
    --fontSizeMedium: 22px;
    --fontSizeMediumPlus: 24px;
    --fontSizeSmall: 20px;
    --fontSizeVerySmall: 18px;
    --fontSizeBig: 26px;
    --fontSizeVeryBig: 20px;
    --fontSizeLarge: 28px;
    --fontSizeLargePlus: 44px;
    --fontSizeHuge: 56px;
  }
}

$fontSizeMedium: var(--fontSizeMedium);
$fontSizeMediumPlus: var(--fontSizeMediumPlus);
$fontSizeSmall: var(--fontSizeSmall);
$fontSizeVerySmall: var(--fontSizeVerySmall);
$fontSizeBig: var(--fontSizeBig);
$fontSizeVeryBig: var(--fontSizeVeryBig);
$fontSizeLarge: var(--fontSizeLarge);
$fontSizeLargePlus: var(--fontSizeLargePlus);
$fontSizeHuge: var(--fontSizeHuge);

:export {
  fontSizeMedium: $fontSizeMedium;
  fontSizeMediumPlus: $fontSizeMediumPlus;
  fontSizeSmall: $fontSizeSmall;
  fontSizeVerySmall: $fontSizeVerySmall;
  fontSizeBig: $fontSizeBig;
  fontSizeVeryBig: $fontSizeVeryBig;
  fontSizeLarge: $fontSizeLarge;
  fontSizeLargePlus: $fontSizeLargePlus;
  fontSizeHuge: $fontSizeHuge;
}
