.gameConfiguratorScreen {
  padding: 56px 120px;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;

  .header {
    display: flex;
    font-family: NunitoSemiBold;
    font-size: $fontSizeLarge;
    line-height: 120%;
    text-transform: uppercase;
    color: $white;
    justify-content: center;
    margin-bottom: 56px;
  }

  .sectionsWrapper {
    background-color: $backgroundWhiteOpacity;
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    border-radius: 16px;

    .sectionsHeaders {
      height: 50px;
      background-color: $backgroundWhiteOpacity;
      border-radius: 16px 16px 0 0;
      display: flex;

      .sectionHeaderWrapper {
        display: flex;
        flex: 1;
        box-sizing: border-box;
        border-bottom: 2px solid transparent;
        justify-content: center;
        align-items: center;
        font-size: $fontSizeSmall;
        line-height: 22px;
        color: $white;
        position: relative;

        &.active {
          border-color: $primary;

          .sectionId {
            background-color: $primary;
            color: $black;
            border-color: $primary;
          }
        }

        .sectionId {
          box-sizing: border-box;
          border: 1px solid $gray;
          width: 22px;
          height: 22px;
          border-radius: 11px;
          background-color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          color: $gray;
          margin-right: 8px;

          img {
            width: 22px;
            height: 22px;
          }
        }

        .nextIcon {
          height: 16px;
          width: 16px;
          position: absolute;
          right: -8px;
          z-index: 1;
        }
      }
    }

    .sectionBody {
      display: flex;
      flex: 1;

      .sectionsTitle {
        font-size: $fontSizeMedium;
        line-height: 24px;
        text-align: center;
        color: $white;
        margin-top: 48px;
        margin-bottom: 16px;
      }

      .industrySection,
      .digitalTechnologySection,
      .educationalGoalsSection,
      .gameModeSection {
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: center;
      }
      .digitalTechnologySection {
        .categoryIcon {
          width: 24px;
          height: 24px;
          margin-right: 8px;
        }
      }

      .bubbles {
        display: flex;
        flex-wrap: wrap;
        padding: 0 72px;
        justify-content: center;
      }

      .bubble {
        display: flex;
        font-family: NunitoBold;
        padding: 8px 12px;
        background-color: $backgroundOpacity_medium;
        border-radius: 16px;
        font-size: $fontSizeSmall;
        line-height: 28px;
        text-transform: uppercase;
        margin: 0px 4px 16px 4px;
        cursor: pointer;
        align-items: center;
        // color: $white;

        &:hover {
          background-color: #fff;
        }

        &.selected {
          background-color: #fff;
        }

        .selectedIcon {
          width: 21px;
          height: 21px;
          margin-right: 8px;
        }
      }

      .educationalGoalsSection {
        padding: 0 80px 40px 80px;
        .MuiInputBase-root {
          box-sizing: border-box;
          border: 1px solid #00f8ff;
          border-radius: 16px;
          width: 100%;
          background-color: $mainBackground;

          .MuiSelect-select {
            box-sizing: border-box;
            padding: 7px 0 4px 0;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 32px;
            font-size: $fontSizeSmall;
            line-height: 22px;
            color: $white;

            text-align: center;
            text-transform: uppercase;
          }
        }

        .MuiOutlinedInput-notchedOutline {
          border: none;
        }

        .subtask {
          display: flex;
          flex-direction: column;
          background-color: $backgroundOpacity_medium;
          border-radius: 16px;
          width: 100%;
          margin-bottom: 16px;
          padding: 24px;
          cursor: pointer;

          &:hover {
            background-color: #fff;
            color: #000;
          }

          &.selected {
            background-color: #fff;

            .subtask_title {
              margin-bottom: 20px;
            }
          }

          .subtask_title {
            display: flex;
            align-items: center;
            font-family: NunitoBold;
            font-size: $fontSizeMedium;
            line-height: 24px;
          }

          .selectedIcon {
            width: 22px;
            height: 22px;
            margin-right: 18px;
          }

          .subtask_knowledge,
          .subtask_skills {
            margin-left: 40px;
            font-size: $fontSizeSmall;
            line-height: 22px;

            .subtask_section_title {
              font-family: NunitoBold;
            }

            ul {
              padding: 0 24px;
              margin: 0;
            }
          }

          .subtask_knowledge {
            margin-bottom: 20px;
          }

          .time {
            margin-top: 24px;
            display: flex;
            align-items: center;
            font-size: $fontSizeMedium;
            font-family: NunitoBold;

            .clockIcon {
              width: 16px;
              height: 16px;
              margin-right: 16px;
            }

            :nth-child(3) {
              padding-left: 4px;
            }
          }
        }
      }

      .gameModeSection {
        padding: 0 56px 56px 56px;
        .bubble {
          width: 80px;

          .bubble_text {
            display: flex;
            flex: 1;
            justify-content: center;
          }
        }

        .modeSpecification {
          display: flex;
          font-size: $fontSizeSmall;
          line-height: 22px;
          font-family: NunitoSemiBold;
          color: $white;
          margin-top: 9px;

          .modeSpecification_title {
            margin-right: 30px;
            white-space: nowrap;
          }

          .specificationRow {
            display: flex;
            flex-direction: column;

            .gameModeSpectificationRow {
              &:nth-of-type(1) {
                margin-top: 0;
              }
            }
          }
        }

        .styledTextInput {
          margin-bottom: 22px;
        }

        .skills {
          font-size: $fontSizeSmall;
          line-height: 22px;
          font-family: NunitoSemiBold;
          margin-top: 24px;

          .skills_title {
            color: $white;
            padding-right: 8px;
          }

          .primary {
            color: $primary;
            text-transform: uppercase;
          }
        }

        .info {
          color: $white;
          font-size: $fontSizeSmall;
          line-height: 22px;
        }
      }
    }

    .buttons {
      display: flex;
      padding: 24px 0;
      box-sizing: border-box;
      border-top: 1px solid $mainBackground;
      justify-content: center;

      .styledButton {
        min-width: 120px;

        &.MuiButton-contained {
          margin-left: 16px;
        }
      }
    }
  }
}
