.gamesTab {
  display: flex;
  flex-direction: column;

  .redirectToConfigurator {
    display: flex;
    background-color: $mainBackground;
    border-radius: 16px;
    padding: 4px;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;
    color: $white;

    .styledButton {
      padding: 0 0 0 5px;
      margin-top: 1px;
      height: unset;
    }
  }

  .createGroupButton {
    align-self: flex-end;
    margin-bottom: 24px;
  }

  td {
    padding: 0;
    &.headerTc {
      background-color: rgba($mainBackground, 0.56);
      color: $white;
      font-size: $fontSizeSmall;
      line-height: 22px;
      padding: 12px 0;

      span {
        padding: 0px 8px;
      }

      &.small {
        width: 25%;

        span {
          border-left: 1px solid $gray; //TODO change to something lighter (more opacity)
        }
      }
    }
  }
  tr {
    &.groupRow {
      th {
        padding: 7px 0;
        font-size: $fontSizeSmall;
        line-height: 22px;
      }

      .groupHeader {
        display: flex;
        align-items: center;
      }
    }

    &.userRow {
      background-color: $mainBackground;
      td {
        padding: 7px 0px 7px 8px;
        width: 25%;
        color: $white;
      }
    }
  }

  th {
    color: $white;
    padding: 0;

    .buttons {
      display: flex;
      justify-content: flex-end;
    }

    button {
      color: $white;
    }
  }

  .styledFiltersComponent {
    margin-bottom: 24px;
  }

  .gameData {
    display: flex;
    background-color: $mainBackground;
    flex-direction: column;
    padding: 12px 8px;
    color: $white;
    font-size: $fontSizeSmall;

    .header {
      padding-bottom: 12px;
      font-family: NunitoSemiBold;
    }

    .subtask {
      padding-bottom: 24px;
      font-family: NunitoSemiBold;
    }

    .bottomInfoWrapper {
      display: flex;
      align-items: center;

      .bottomInfoDiv {
        margin-right: 24px;
        .value {
          text-transform: uppercase;
          font-family: NunitoSemiBold;
          padding-left: 5px;
        }

        .styledButton {
          padding: 0 0 0 5px;
          height: unset;
        }
      }
    }
  }
}
