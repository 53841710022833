.groupsTab {
  display: flex;
  flex-direction: column;
  .createGroupButton {
    align-self: flex-end;
    margin-bottom: 24px;
  }

  td {
    &.css-1ex1afd-MuiTableCell-root {
      border-bottom-color: #fff;
    }

    padding: 0;
    &.headerTc {
      background-color: rgba($mainBackground, 0.56);
      color: $white;
      font-size: $fontSizeSmall;
      line-height: 22px;
      padding: 12px 0;

      span {
        padding: 0px 8px;
      }

      &.small {
        width: 25%;

        span {
          border-left: 1px solid $gray; //TODO change to something lighter (more opacity)
        }
      }
    }
  }
  tr {
    // &.css-1ex1afd-MuiTableCell-root {
    //   border-bottom-color: pink;
    // }
    &.groupRow {
      th {
        padding: 7px 0;
        font-size: $fontSizeSmall;
        line-height: 22px;
      }

      .groupHeader {
        display: flex;
        align-items: center;
      }
    }

    &.userRow {
      background-color: $mainBackground;
      td {
        padding: 7px 0px 7px 8px;
        width: 25%;
        color: $white;
      }
    }
  }

  th {
    color: $white;
    padding: 0;

    &.css-1ex1afd-MuiTableCell-root {
      border-bottom-color: #fff;
    }

    .buttons {
      display: flex;
      justify-content: flex-end;
    }

    button {
      color: $white;
    }
  }
}
