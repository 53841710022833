.FAQScreen {
  display: flex;
  flex-direction: column;
  padding: 56px 223px;
  align-items: center;
  color: $white;

  .componentHeader {
    font-family: NunitoSemiBold;
    font-size: $fontSizeLarge;
    line-height: 120%;
    text-transform: uppercase;
    margin-bottom: 56px;
  }

  .smallInfo {
    font-size: $fontSizeMedium;
    line-height: 22px;
  }

  .sectionHeader {
    font-family: NunitoSemiBold;
    font-size: $fontSizeVeryBig;
    line-height: 32px;
    margin-bottom: 24px;
    margin-top: 56px;

    &.capslock {
      text-transform: uppercase;
    }
  }

  .styledButton {
    margin-top: 24px;
  }

  .faqWrapper {
    min-width: 500px;
  }

  .accordion {
    margin-top: 24px;
    background-color: $blueLighter;
    border-radius: 16px;
    padding: 12px;
    color: #fff;

    &:nth-child(1) {
      margin-top: 0;
    }

    .accordionHeader {
      font-size: $fontSizeMedium;
      line-height: 22px;
      color: $primary;
      text-transform: none;

      .downIcon {
        width: 16px;
        height: 16px;
        color: $white;
      }
    }

    .bodyWrapper {
      padding-top: 8px;
      padding-left: 30px;
      font-size: $fontSizeMedium;
      line-height: 22px;
    }
  }

  .contactFormWrapper {
    width: 800px;
    padding: 24px;
    border: 2px solid $primary;
    border-radius: 16px;
    box-sizing: border-box;

    .textLabel {
      font-size: $fontSizeMedium;
      line-height: 22px;
      text-transform: uppercase;
      padding-bottom: 8px;
    }

    .styledButton {
      left: 50%;
      -ms-transform: translateX(-50%);
      transform: translateX(-50%);
    }
  }

  .section {
    .MuiButton-root {
      margin-top: 0;
    }
  }
}
