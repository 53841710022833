.organizationPanelScreen {
  display: flex;
  flex: 1;
  flex-direction: column;

  .top {
    padding: 42px 120px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $white;

    > span {
      font-size: $fontSizeLarge;
      line-height: 120%;
      text-transform: uppercase;
      font-family: NunitoSemiBold;
    }

    .top_leftPart {
      display: flex;

      .topPartNumberContainer {
        display: flex;
        flex-direction: column;
        margin-left: 32px;

        .label {
          font-size: $fontSizeSmall;
          line-height: 22px;
          padding-bottom: 4px;
        }

        .value {
          font-size: $fontSizeVeryBig;
          line-height: 32px;
        }
      }
    }
  }

  .bottom {
    flex: 1;
    padding: 6px 0px 58px 0px;
    box-sizing: border-box;
    background-color: $backgroundOpacity_light;
    backdrop-filter: blur(20px);

    .tabContent {
      margin: 0 120px;
    }

    tr,
    td,
    th {
      font-family: NunitoRegular;
    }
  }

  .subtask_title {
    display: flex;
    justify-content: center;
    font-size: $fontSizeMedium;
    color: $white;
    margin-bottom: 24px;
    &:nth-child(3) {
      margin-top: 24px;
    }
  }
}
