.gamesCategoriesComponent {
  .header {
    padding: 24px 120px 0 120px;
    font-family: NunitoSemiBold;
    font-size: $fontSizeVeryBig;
    line-height: 28px;
    text-transform: uppercase;
    color: $white;
  }

  .categories {
    display: flex;
    flex-wrap: wrap;
    padding: 0 120px 56px 120px; //TODO 56px 16px na małych ekranach

    .category {
      margin-top: 16px;
      background-color: $backgroundOpacity_medium;
      color: $black;
      white-space: nowrap;
      font-family: NunitoSemiBold;
      font-size: $fontSizeSmall;
      line-height: 28px;
      text-transform: uppercase;
      padding: 8px 10px;
      border-radius: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 8px;
      box-sizing: border-box;
      cursor: pointer;
      border: 2px solid transparent;

      &.selected {
        background-color: $white;
        border: 2px solid $primary;
      }

      img {
        margin-right: 8px;
      }
    }
  }

  .categoryInfoWrapper {
    padding: 0 120px 48px 120px;
    color: $white;

    .categoryLongName {
      font-family: NunitoBold;
      font-size: $fontSizeLarge;
      line-height: 22px;
      text-transform: uppercase;
      padding-bottom: 24px;
    }

    .categoryDescription {
      font-family: NunitoRegular;
      font-size: $fontSizeBig;
      line-height: 28px;
      padding-bottom: 24px;
    }

    .categoryTags {
      display: flex;
      font-family: NunitoBold;
      align-items: center;

      .technologies {
        font-size: $fontSizeVeryBig;
        line-height: 22px;
        text-transform: uppercase;
        padding-right: 16px;
      }

      .tag {
        font-family: NunitoBold;
        text-transform: uppercase;
        font-size: $fontSizeSmall;
        line-height: 28px;
        margin-left: 8px;
        color: $black;
        background-color: $white;
        border-radius: 16px;
        padding: 2px 12px;
      }
    }
  }
}
