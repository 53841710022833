.accordion {
  .accordionBody {
    overflow: hidden;
    max-height: 4000px;
    transition: max-height 1s ease-in-out;
  }

  .accordionBody[aria-expanded="true"] {
    max-height: 0px;
    transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
  }
}
