.resetPasswordScreen {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $white;
  position: relative;

  .logoWrapper {
    display: flex;
    flex-direction: column;
    min-width: 460px;
    width: 33vw;
    align-items: center;
    justify-content: center;
    height: 250px;
    min-height: 80px;
    max-height: 250px;
  }

  .resetPasswordInfo {
    white-space: pre-line;
    text-align: center;
    margin-bottom: 22px;
    margin-top: -15px;
  }

  .backTologin {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 460px;
    width: 33vw;
    border-top: 1px solid #7a7a7a;
    margin-top: 22px;
    padding-top: 22px;
  }
}
